.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.main-wrapper {
  background-color: #f1f1f1;
  color: #000;
}

.main-wrapper_mobile {
  position: relative;
  display: block;
  width: 480px;
  max-width: 100%;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  font-family: 'Nunito Sans', sans-serif;
  color: #8a9198;
  font-weight: 600;
}

.section-navbar_menu {
  background-color: #fff !important;
}

.section-navbar_menu-items {
  border: 0px solid #000;
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.menu-button {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.nav-menu {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.section-navbar_menu-wrapper {
  display: -ms-grid;
  display: grid;
  padding: 1rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 2.25fr 0.25fr;
  grid-template-columns: 2.25fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-navbar_logo {
  font-size: 1.25rem;
  font-weight: 700;
}

.logo-span {
  color: #f8633b;
}

.tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-bottom: 1px solid #a4a6b3;
  -o-object-fit: fill;
  object-fit: fill;
}

.tabs {
  background-color: #fff;
  color: #a4a6b3;
}

.tabs.w--current {
  display: block;
  margin-right: 20px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-style: solid;
  border-width: 0px 0px 2px;
  border-color: #f8633b #000;
  border-radius: 0px;
  background-color: #fff;
  color: #000;
}

.tabs {
  margin-right: 20px;
  padding-right: 0px;
  padding-left: 0px;
}

.tab-icons {
  display: block;
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  text-align: center;
}

.tab-title {
  font-size: 14px;
  font-weight: 700;
}

.tab-title.is-active {
  color: #252733;
}

.tab-icons_inactive {
  max-height: 1.2rem;
}

.tab-icons_active {
  display: none;
  max-height: 1.2rem;
}

.tab-pane-tab-1 {
  min-height: 100px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.section-status_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-right: 1rem;
  margin-left: 1rem;
  padding: 0.5rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ededed;
}

.section-status {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.section-status_button-active {
  width: 49%;
  padding: 12px 20px;
  border-radius: 8px;
  background-color: #ededed;
  color: #6f7588;
  font-size: 1rem;
  font-weight: 700;
  text-align: center !important;
}

.section-status_button-active:hover {
  background-color: rgba(249, 250, 251, 0.7);
}

.section-status_button-active.w--current {
  background-color: #fff;
  color: #f8633b;
}

.section-status_button-inactive {
  font-weight: 600;
}

.section-status_button-inactivee {
  width: 49%;
  padding: 12px 20px;
  border-radius: 8px;
  background-color: transparent;
  color: #6f7588;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.section-status_button-inactivee:focus {
  background-color: #fff;
  color: #f8633b;
}

.section-divider {
  height: 6px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f9fafb;
}

.section-appointment_list {
  position: relative;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.section-appointment_list-item {
  position: relative;
  margin-bottom: 1rem;
  padding: 1.5rem 0.875rem;
  border-style: solid;
  border-width: 1px;
  border-color: #ededed;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 1px -1px 20.03px 0 rgba(0, 0, 0, 0.02);
}

.section-appointment_layout {
  grid-column-gap: 16px;
  grid-row-gap: 29px;
  -ms-grid-columns: auto minmax(auto, 2.75fr);
  grid-template-columns: auto minmax(auto, 2.75fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.item-image {
  height: 120px;
  max-width: none;
  border-style: none;
  border-width: 1px;
  border-color: #f8633b;
  border-radius: 4px;
}

.list-item-details_dates {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.125rem;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.appointment-details {
  margin-top: auto;
  margin-bottom: 0px;
  color: #252733;
  font-size: 1rem;
  line-height: 125%;
}

.text_appointment-drname {
  color: #6f7588;
  font-size: 14px;
}

.icon-size-tiny {
  height: 1rem;
  margin-right: 6px;
}

.text_appointment-date {
  color: #252733;
}

.text_appointment-time {
  color: #252733;
}

.section_appointment-divider {
  height: auto;
  margin-right: 4px;
  margin-left: 4px;
}

.text-span {
  color: #252733;
}

.section-appointment_list-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.section-appointment_button {
  width: 49%;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 1.75rem;
  font-size: 16px;
  text-align: center;
}

.section-appointment_button.secondary-btn {
  border-style: solid;
  border-width: 1px;
  border-color: #ededed;
}

.primary-btn {
  background-color: #f8633b;
  color: #fff;
  font-weight: 700;
}

.secondary-btn {
  background-color: #fff;
  color: #f8633b;
  font-weight: 700;
}

.section-checkins {
  margin-right: 1rem;
  margin-left: 1rem;
}

.text-heading {
  margin-top: 10px;
  color: #252733;
  font-size: 1.25rem;
  line-height: 125%;
  justify-self: start;
}

.section-checkins_list-item {
  margin-bottom: 1rem;
  padding: 1.375rem 1rem 1rem;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 1px -1px 20.03px 0 rgba(0, 0, 0, 0.08);
}

.section-checkins_button {
  width: 49%;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 1.75rem;
  font-size: 16px;
  text-align: center;
}

.section-checkins_button.secondary-btn {
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #bbb;
}

.text-checkins_appointment-name {
  color: #252733;
  font-size: 16px;
  line-height: 100%;
}

.section-checkins_layout {
  margin-bottom: 40px;
  -ms-grid-columns: 0.25fr 1fr;
  grid-template-columns: 0.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-checkins_layout.is-last {
  margin-bottom: 0px;
}

.text_checkins-date {
  margin-top: auto;
  margin-bottom: auto;
  color: #8a9198;
  font-size: 14px;
}

.text_checkins-date._1 {
  margin-left: 10px;
}

.icon-size-medium {
  height: 50px;
  max-width: none;
  border-radius: 5px;
}

.section-checkins_list-items {
  margin-bottom: 0px;
}

.section-offerings {
  margin-right: 1rem;
  margin-left: 1rem;
}

.section-offerings_layout {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.offering-item_wrapper {
  height: 200px;
  padding: 12px;
  border-radius: 6.02px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('../image/images/image-1-2.png');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../image/images/image-1-2.png');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.offering-item_wrapper._1 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)), url('../image/images/Mental-Health-1.jpg');
  background-image: linear-gradient(180deg, transparent, transparent), url('../image/images/Mental-Health-1.jpg');
}

.offering-item_wrapper._2 {
  background-image: linear-gradient(350deg, #000, hsla(0, 0%, 100%, 0)), url('../image/images/Sexual-Health---Women-1-1.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.offering-item_wrapper._3 {
  background-image: linear-gradient(338deg, #000, hsla(0, 0%, 100%, 0)), url('../image/images/Sexual-Health-Men-Option-2-1-1.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.offering-item_wrapper._4 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)), url('../image/images/Supplements-1-1.jpg');
  background-image: linear-gradient(180deg, transparent, transparent), url('../image/images/Supplements-1-1.jpg');
}

.offering-item {
  position: relative;
  top: 85%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading {
  font-size: 1rem;
}

.text-offerings {
  margin-top: 0px;
  margin-bottom: 4px;
  color: #fff;
  font-size: 1rem;
  line-height: 100%;
}

.offering-item-icon {
  width: 16px;
  height: 16px;
  max-width: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.offering-item-copy {
  position: relative;
  top: 80%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-offerings-copy {
  margin-top: 0px;
  margin-bottom: 4px;
  color: #a4a6b3;
  font-size: 0.8rem;
  line-height: 100%;
  font-weight: 400;
}

.section-help {
  margin-right: 1rem;
  margin-left: 1rem;
}

.section-help_image {
  position: relative;
  display: block;
  margin-top: 2rem;
  margin-bottom: 2rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.paragraph-small {
  line-height: 125%;
  font-weight: 400;
}

.section-help_out-image {
  position: absolute;
  left: auto;
  top: 20%;
  right: 0%;
  bottom: auto;
}

.padding-top {
  margin: auto 0px 0px;
}

.padding-small {
  margin: 1.5rem;
}

.section-profile-personal-info {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 2.25fr 1fr;
  grid-template-columns: 2.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.personal-info-wrapper {
  display: -ms-grid;
  display: grid;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.25fr 2fr;
  grid-template-columns: 0.25fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.page-padding {
  position: relative;
  min-height: auto;
  padding: 1rem;
  background-color: white;
}

.section-primary_button {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 1.75rem;
  font-size: 16px;
  text-align: center;
}

.section-primary_button.secondary-btn {
  border-style: solid;
  border-width: 1px;
  border-color: #ededed;
}

.field-label {
  font-weight: 400;
  color: #8a9198;
}
.rmsc > .dropdown-container {
  min-height: 50px !important;
  margin-bottom: 24px  !important;
  padding: 14px 0px 14px 12px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #ededed !important;
  border-radius: 4px !important;
  box-shadow: 1px 1px 3px 0 hsla(0, 0%, 92.9%, 0) !important;
  color: #000 !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.text-field {
  margin-bottom: 24px;
  padding: 14px 0px 24px;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-color: #000 #000 #ededed;
  border-radius: 0px;
  color: #000;
  font-size: 1rem;
  font-weight: 600;
}

.text-field::-webkit-input-placeholder {
  color: #252733;
}

.text-field:-ms-input-placeholder {
  color: #252733;
}

.text-field::-ms-input-placeholder {
  color: #252733;
}

.text-field::placeholder {
  color: #252733;
}

.we-have-sent-you-the-otp-to-83009-88763 {
  margin-top: 20px;
}

.form-stacked {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-shipping-header {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 2.25fr 1fr;
  grid-template-columns: 2.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-orders-wrapper {
  position: relative;
  min-height: auto;
}

.section-footer-wrapper {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: #000;
}

.section-footer-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.is-white {
  color: #fff;
}

.margin-top-30 {
  display: block;
  margin-top: 30px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-footer-image-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 36px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-footer-copyright {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.text-copyright {
  font-size: 1rem;
  line-height: 125%;
  font-weight: 600;
  text-align: center;
}

.read-text-field-border {
  min-height: 50px !important;
  margin-bottom: 24px  !important;
  padding: 14px 0px 14px 0px !important;
  border-bottom: 1px solid #cccccc !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.text-field-border {
  min-height: 50px !important;
  margin-bottom: 24px  !important;
  padding: 14px 0px 14px 12px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #ededed !important;
  border-radius: 4px !important;
  box-shadow: 1px 1px 3px 0 hsla(0, 0%, 92.9%, 0) !important;
  color: #000 !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.text-field-border::-webkit-input-placeholder {
  color: #252733;
}

.text-field-border:-ms-input-placeholder {
  color: #252733;
}

.text-field-border::-ms-input-placeholder {
  color: #252733;
}

.text-field-border::placeholder {
  color: #252733;
}

.section-save-changes-btn-wrapper {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.section-edit-info-items {
  position: relative;
  border: 0px solid #000;
}

.section-edit-info {
  min-height: 100vh;
}

.section-appointment-details {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.5fr 0.5fr;
  grid-template-columns: 0.5fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text_appointment-regno {
  color: #252733;
}

.appointment-status-completed {
  display: inline-block;
  width: auto;
  border-radius: 4px;
  background-color: #dbf9ed;
  color: #12d589;
  font-weight: 700;
}

.text-appointement-status {
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 200%;
}

.margin-top-10 {
  margin-top: 10px;
}

.div-block {
  display: -ms-grid;
  display: grid;
  padding-top: 10px;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  grid-template-areas: "Area";
  -ms-grid-columns: 0.25fr 0px 1fr;
  grid-template-columns: 0.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.is-orange {
  color: #f8633b;
}

.is-orange:hover {
  color: #f03300;
}

.section-appointment-details-copy {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1.25fr 1fr;
  grid-template-columns: 1.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-appointment-details_list-item-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #f0f5f9;
}

.image {
  display: block;
}

.section-btn-wrapper {
  position: static;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.section-upload {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px dashed #898f97;
  border-radius: 4px;
}

.section-upload-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.image-2 {
  height: 50px;
}

.section-report_list-item {
  margin-bottom: 1rem;
  padding: 0.875rem 0.875rem 0rem;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 1px -1px 20.03px 0 rgba(0, 0, 0, 0.08);
}

.text_report-uploaded {
  color: #00b37a;
  font-size: 14px;
}

.section-appointment_layout-copy {
  grid-column-gap: 9px;
  grid-row-gap: 29px;
  -ms-grid-columns: 0.75fr 4.25fr 1fr;
  grid-template-columns: 0.75fr 4.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.item-icon {
  height: 30px;
  max-width: none;
  border-radius: 5px;
}

.section_button-wrapper {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 1.75rem;
  font-size: 16px;
  text-align: center;
}

.section_button-wrapper.secondary-btn {
  border-style: solid;
  border-width: 1px;
  border-color: #ededed;
  box-shadow: 1px 1px 3px 2px hsla(0, 0%, 92.9%, 0.24);
}

.section_button-wrapper.inactive-btn:hover {
  background-color: #f8633b;
  color: #fff;
}

.section_button-wrapper.inactive-btn:active {
  background-color: #f8633b;
  color: #fff;
}

.section_button-wrapper.margin-top-20 {
  margin-top: 20px;
}

.section-appointment_button-copy {
  width: 60%;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 1.75rem;
  font-size: 16px;
  text-align: center;
}

.section-appointment_button-copy.secondary-btn {
  border-style: solid;
  border-width: 1px;
  border-color: #ededed;
}

.section-appointment-time-wrapper {
  display: -ms-grid;
  display: grid;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-appointment-time-date {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border: 1px solid #e6e6e8;
  border-radius: 4px;
  box-shadow: 1px 1px 4px 0 #e6e6e8;
}

.section-appointment-time-date-left {
  margin-left: 0px;
}

.section-appointment-time-date-right {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.div-block-2 {
  width: 2px;
  background-color: #e6e6e8;
}

.div-block-2.margin-left-30 {
  height: 1.75rem;
}

.bg-grey {
  background-color: #f9fafb;
}

.section-appointment-time-schedule {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.floating-number {
  position: absolute;
  left: auto;
  top: 8%;
  right: 5%;
  bottom: auto;
}

.text-floating {
  color: #8a9198;
}

.icon-image {
  height: auto;
  max-width: none;
  border-radius: 5px;
}

.section-no-appointment {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30% 20px 70%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-no-appointment-wrapper {
  text-align: center;
}

.section-no-appointment_image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.text-no-appointment {
  color: #252733;
  font-size: 24px;
  line-height: 125%;
}

.paragraph {
  width: 30ch;
}

.is-underlined {
  text-decoration: underline;
}

.calendar-copy {
  height: 2rem;
  margin-right: 6px;
}

.section-appointment-details-item {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.section-appointment-details-items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 200px;
  padding: 1rem 1rem 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 4px;
  background-color: #e5f4ff;
  background-image: url('../image/images/Frame-17092.png');
  background-position: 50% 20%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  box-shadow: -1px 0 3px 2px #ededed;
}

.section-appointment-details-items._2 {
  background-color: #fff1d0;
}

.section-appointment-details-items._3 {
  background-color: #d4f6e8;
}

.section-appointment-details-items._4 {
  background-color: #ffe4ef;
}

.section-appointment-time-wrapper-copy {
  display: -ms-grid;
  display: grid;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1.25fr;
  grid-template-columns: 1fr 1.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dropdown {
  display: block;
  max-width: 95%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-style: solid;
  border-width: 1px;
  border-color: #ededed;
  border-radius: 4px;
  box-shadow: none;
}

.text-block {
  font-size: 16px;
}

.icon {
  color: #f8633b;
}

.dropdown-2 {
  display: block;
}

.dropdown-link {
  background-color: #fff;
  color: #f8633b;
}

.spacer-120 {
  min-height: 120px;
}

.dropdown-list {
  box-shadow: 1px 1px 3px 1px #ededed;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bg-shadow {
  padding: 1rem 0.5rem;
  box-shadow: 1px 1px 4px 2px #ededed;
}

.section-faq {
  background-color: #f8fafc;
}

.padding-inner {
  padding: 0.5rem;
}

.text-size-regular {
  margin-top: 10px;
  color: #252733;
  font-size: 1rem;
  line-height: 125%;
}

.section-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-medium {
  color: #252733;
  font-size: 2rem;
}

.margin-top-40 {
  display: block;
  margin-top: 40px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.login-number {
  padding: 1rem;
  border: 1px none #000;
  border-radius: 4px;
  box-shadow: -1px 0 2px 2px #ededed;
}

.inactive-btn {
  background-color: #e1e3ea;
  color: #b6bac8;
  font-size: 1rem;
  font-weight: 700;
}

.div-block-4 {
  text-align: center;
}

.text-span-2 {
  color: #8a9198;
}

.text-weight-regular {
  font-weight: 400;
}

.text-span-3 {
  color: #f8633b;
  font-weight: 700;
}

.margin-top-60 {
  display: block;
  margin-top: 60px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.btn-shadow {
  margin-bottom: 1.5rem !important;
  padding: 2rem !important;
  border: 1px none #000 !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 3px 1px #ededed !important;
  font-size: 1rem !important;
  line-height: 125% !important;
}

.btn-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 4px;
}

.is-grey {
  color: #919ba9;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-20 {
  margin-left: 20px;
}

.section-login-footer {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.paragraph-2 {
  border: 1px none #000;
  color: #8a9198;
  text-align: center;
}

.link-block {
  text-decoration: none;
}

.link-block-2 {
  text-decoration: none;
}

.link-block-3 {
  color: #a4a6b3;
  text-decoration: none;
}

.link-block-3:hover {
  color: #f8633b;
}

.div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.verticale-divider {
  width: 1px;
  height: 1.2rem;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #a4a6b3;
}

.menu-icon4_line-bottom {
  width: 24px;
  height: 2px;
  background-color: #000;
}

.menu-icon4_line-top {
  width: 24px;
  height: 2px;
  padding-right: 0px;
  padding-bottom: 0px;
  background-color: #000;
}

.dropdown-icon {
  margin-top: 0.75rem;
  margin-right: 1rem;
  margin-bottom: 0.75rem;
}

.navbar4_dropdown-list {
  position: static;
  overflow: hidden;
}

.navbar4_dropdown-list.w--open {
  position: static;
  background-color: transparent;
}

.navbar4_menu-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-top: -4.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar4_button-navbar {
  margin-right: 1.5rem;
  margin-left: 1rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid #000;
  background-color: #000;
  color: transparent;
}

.menu-icon4_line-middle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 2px;
  margin-top: 6px;
  margin-bottom: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
}

.navbar4_logo-link {
  padding-left: 0rem;
}

.navbar4_menu {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  height: 100vh;
  padding-right: 5%;
  padding-left: 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.menu-icon4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar4_component {
  height: 4.5rem;
  padding-right: 5%;
  padding-left: 5%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #000;
  background-color: transparent;
}

.menu-icon_line-middle-top {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  width: 24px;
  height: 2px;
}

.navbar4_link {
  position: static;
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0rem;
  padding: 0.5rem 0rem;
  font-size: 24px;
}

.navbar4_link.w--current {
  color: transparent;
}

.navbar4_dropdown-link {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1.5rem;
  padding: 0.5rem 0rem;
  font-size: 24px;
}

.navbar4_dropdown-link.w--current {
  color: transparent;
}

.menu-icon_line-middle-base {
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #000;
}

.navbar4_dropdown-toggle {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 4rem 0.5rem 0rem;
}

.menu-icon4_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar4_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr -webkit-max-content 1fr;
  -ms-grid-columns: 1fr max-content 1fr;
  grid-template-columns: 1fr -webkit-max-content 1fr;
  grid-template-columns: 1fr max-content 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.navbar4_menu-dropdown {
  margin-left: 0px;
  font-size: 24px;
}

.navbar4_menu-button {
  padding: 0rem;
}

.navbar4_menu-button.w--open {
  background-color: transparent;
}

.navbar4_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sticky-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  padding: 20px;
  background-color: #f4f4f4;
}

.nav-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: 1fr max-content max-content max-content;
  grid-template-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: 1fr max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.nav-logo-link {
  display: block;
  height: 60px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
}

.nav-logo {
  width: auto;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.nav-link {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 0px;
  color: #444;
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: underline;
}

.menu-button-2 {
  padding: 10px;
  border-radius: 100%;
  background-color: #f9eae2;
  color: #f8633b;
}

.menu-button-2.w--open {
  background-color: #f9eae2;
  color: #f8633b;
}

.nav-menu-2 {
  overflow: auto;
  min-height: 100vh;
  background-color: #fff;
}

.nav-link-2 {
  margin: 20px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(164, 166, 179, 0.31);
  font-size: 1rem;
  font-weight: 700;
}

.nav-link-2:hover {
  background-color: rgba(255, 243, 239, 0.76);
  color: #ff4b1a;
  font-weight: 700;
}

.image-4 {
  max-width: 150px;
}

.icon-size-small {
  height: 1.75rem;
  max-width: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.image-6 {
  max-width: 150px;
}

.image-7 {
  max-width: 150px;
}

.section-appointment-details-copy {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 0.25fr;
  grid-template-columns: 1fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.login-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-8 {
  max-width: 150px;
}

.link-block-4 {
  text-decoration: none;
}

.link-block-5 {
  text-decoration: none;
}

.link-block-6 {
  display: block;
  text-decoration: none;
}

.section-otp-wrapper {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.otp-boxes {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border: 1px solid #e1e3ea;
  border-radius: 8px;
}

.otp-boxes:hover {
  border-color: #f8633b;
  color: #f8633b;
}

.otp-boxes:focus {
  border-color: #f8633b;
  color: #f8633b;
}

.paragraph-3 {
  margin-bottom: 0px;
}

.item-image-copy {
  height: 30px;
  max-width: none;
  border-style: solid;
  border-width: 1px;
  border-color: #f8633b;
}

.image-footer {
  max-height: 50px;
  max-width: none;
}

.section-appointment-details-copy {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 11px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.5fr 0.5fr;
  grid-template-columns: 0.5fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-heading-copy {
  margin-top: 10px;
  color: #252733;
  font-size: 1.2rem;
  line-height: 125%;
}

.icon-image-copy {
  height: 60px;
  max-width: none;
  border-radius: 5px;
}

.image-9 {
  height: 25px;
  max-height: 25px;
}

.section-appointment-details-copy {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 2.25fr;
  grid-template-columns: 2.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-appointment-details-copy {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1.5fr 0.5fr;
  grid-template-columns: 1.5fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.link-block-7 {
  text-decoration: none;
}

.section-appointment_layout-copy {
  grid-column-gap: 24px;
  grid-row-gap: 29px;
  -ms-grid-columns: auto minmax(auto, 2fr);
  grid-template-columns: auto minmax(auto, 2fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-appointment-details-copy {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 0.25fr;
  grid-template-columns: 1fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-appointment_layout-copy {
  grid-column-gap: 24px;
  grid-row-gap: 29px;
  -ms-grid-columns: auto minmax(auto, 1.75fr);
  grid-template-columns: auto minmax(auto, 1.75fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 4fr;
  grid-template-columns: 1fr 4fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-appointment_layout-copy {
  grid-column-gap: 24px;
  grid-row-gap: 29px;
  -ms-grid-columns: auto minmax(auto, 1.5fr);
  grid-template-columns: auto minmax(auto, 1.5fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-appointment-details-copy-copy {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 11px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.5fr 0.5fr;
  grid-template-columns: 0.5fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-appointment-details-copyy {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 0.25fr;
  grid-template-columns: 1fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-appointment_layout-copy {
  grid-column-gap: 24px;
  grid-row-gap: 29px;
  -ms-grid-columns: auto minmax(auto, 2.75fr);
  grid-template-columns: auto minmax(auto, 2.75fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.item-image-copy {
  height: 70px;
  max-width: none;
  border-radius: 5px;
}

.image-10 {
  height: 40px;
}

.item-image_main {
  height: 45px;
  max-width: none;
  border-radius: 5px;
}

.floating-number-copy {
  position: absolute;
  left: auto;
  top: 20%;
  right: 5%;
  bottom: auto;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../image/images/Group-16736-3.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.paragraph-4 {
  margin-bottom: auto;
}

.icon-size-back {
  height: 2rem;
  max-width: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.item-image-copy {
  height: 120px;
  max-width: none;
  border-style: solid;
  border-width: 1px;
  border-color: #f8633b;
  border-radius: 8px;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.icon-size-large {
  height: 3.5rem;
  max-width: none;
}

@media screen and (max-width: 991px) {
  .menu-icon4_line-bottom {
    width: 24px;
    height: 2px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: #000;
  }

  .menu-icon4_line-top {
    width: 24px;
    height: 2px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: #000;
  }

  .dropdown-icon {
    left: auto;
    top: auto;
    right: 0%;
    bottom: auto;
    margin-top: 0rem;
    margin-right: 0px;
    margin-bottom: 0rem;
  }

  .navbar4_dropdown-list {
    position: static;
    overflow: hidden;
  }

  .navbar4_dropdown-list.w--open {
    position: static;
    padding: 0px;
    border-style: none;
  }

  .navbar4_button-navbar {
    width: auto;
    margin-top: 0rem;
    margin-left: 1.5rem;
  }

  .menu-icon4_line-middle {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 24px;
    height: 2px;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-right: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
  }

  .navbar4_menu {
    z-index: 1;
    display: block;
    margin-left: 0rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .menu-icon4 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 48px;
    height: 48px;
    margin-right: -0.5rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
  }

  .menu-icon_line-middle-top {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    width: 24px;
    height: 2px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .navbar4_link {
    position: static;
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding: 1rem 0rem;
    font-size: 1.25rem;
  }

  .navbar4_dropdown-link {
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding-top: 1rem;
    padding-right: 0rem;
    padding-bottom: 1rem;
    font-size: 20px;
  }

  .menu-icon_line-middle-base {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 24px;
    height: 2px;
    padding-right: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
  }

  .navbar4_dropdown-toggle {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin: 0rem;
    padding: 0.75rem 2rem 0.75rem 0rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .menu-icon4_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar4_container {
    grid-auto-columns: 1fr;
  }

  .navbar4_menu-dropdown {
    position: relative;
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.25rem;
  }

  .navbar4_menu-button {
    margin-left: -0.5rem;
    padding: 0rem;
  }

  .navbar4_menu-button.w--open {
    background-color: transparent;
  }

  .item-image-copy {
    border-style: none;
  }

  .item-image-copy {
    border-style: solid;
  }
}

@media screen and (max-width: 767px) {
  .navbar4_button-navbar {
    margin-left: 1rem;
    padding: 0.25rem 1rem;
  }

  .navbar4_component {
    height: 4rem;
  }

  .sticky-nav {
    position: relative;
  }

  .nav-grid {
    text-align: center;
  }

  .item-image-copy {
    border-style: solid;
  }
}

@media screen and (max-width: 479px) {
  .section-appointment_list-item {
    overflow: hidden;
  }

  .item-image {
    height: 120px;
  }

  .section-appointment_list-item-details {
    margin-bottom: 10px;
  }

  .icon-size-tiny {
    display: block;
  }

  .offering-item_wrapper {
    display: block;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('../image/images/image-1-2.png');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../image/images/image-1-2.png');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }

  .offering-item_wrapper._1 {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)), url('../image/images/Mental-Health.jpg');
    background-image: linear-gradient(180deg, transparent, transparent), url('../image/images/Mental-Health.jpg');
  }

  .offering-item_wrapper._2 {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.73))), url('../image/images/Sexual-Health---Women.jpg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.73)), url('../image/images/Sexual-Health---Women.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
    text-decoration: none;
  }

  .offering-item_wrapper._3 {
    background-image: linear-gradient(350deg, rgba(0, 0, 0, 0.68), transparent), url('../image/images/Sexual-Health-Men-Option-2.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }

  .offering-item_wrapper._4 {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)), url('../image/images/Supplements.jpg');
    background-image: linear-gradient(180deg, transparent, transparent), url('../image/images/Supplements.jpg');
  }

  .offering-item {
    top: 80%;
    margin-bottom: 0px;
  }

  .personal-info-wrapper {
    -ms-grid-columns: 0.5fr 2fr;
    grid-template-columns: 0.5fr 2fr;
  }

  .section-appointment-details {
    grid-column-gap: 6px;
    -ms-grid-columns: 1.5fr 1.5fr;
    grid-template-columns: 1.5fr 1.5fr;
  }

  .div-block {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 16px;
    -ms-grid-columns: 0.25fr 1fr;
    grid-template-columns: 0.25fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .section-report_list-item {
    margin-bottom: 0rem;
    padding-bottom: 0.875rem;
  }

  .section-appointment_layout-copy {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-grid-columns: auto minmax(auto, 2.75fr);
    grid-template-columns: auto minmax(auto, 2.75fr);
  }

  .item-icon {
    display: none;
  }

  .section-appointment_button-copy.secondary-btn {
    width: 100%;
  }

  .section-appointment-time-date {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .div-block-2 {
    margin-right: 10px;
  }

  .section-appointment-details-item {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .bg-shadow {
    box-shadow: 1px 1px 3px 3px #ededed;
  }

  .btn-shadow {
    border-style: solid;
    border-color: #ededed;
    box-shadow: 0 1px 3px 1px hsla(0, 0%, 92.9%, 0.43);
  }

  .link-block {
    text-decoration: none;
  }

  .link-block-2 {
    text-decoration: none;
  }

  .sticky-nav {
    padding: 0px;
  }

  .nav-grid {
    grid-auto-flow: row;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 100px;
    grid-template-rows: 100px;
  }

  .nav-logo-link {
    border-top: 1px solid #c4c4c4;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #c4c4c4;
  }

  .icon-size-small {
    min-width: 20px;
  }

  .icon-size-small.hide {
    display: none;
  }

  .image-6 {
    max-width: 150px;
  }

  .section-appointment-details-copy {
    grid-column-gap: 6px;
    -ms-grid-columns: 1.5fr 1.5fr;
    grid-template-columns: 1.5fr 1.5fr;
  }

  .otp-boxes:hover {
    border-color: #f8633b;
  }

  .otp-boxes:focus {
    border-color: #f8633b;
  }

  .paragraph-3 {
    color: #000;
  }

  .item-image-copy {
    border-style: none;
  }

  .section-appointment-details-copy {
    grid-column-gap: 6px;
    -ms-grid-columns: 1.5fr 1.5fr;
    grid-template-columns: 1.5fr 1.5fr;
  }

  .text-heading-copy {
    font-size: 1rem;
  }

  .section-appointment-details-copy {
    grid-column-gap: 6px;
    -ms-grid-columns: 1.5fr 1.5fr;
    grid-template-columns: 1.5fr 1.5fr;
  }

  .section-appointment-details-copy {
    grid-column-gap: 6px;
    -ms-grid-columns: 1.5fr 1.5fr;
    grid-template-columns: 1.5fr 1.5fr;
  }

  .section-appointment-details-copy {
    grid-column-gap: 6px;
    -ms-grid-columns: 1.5fr 0.75fr;
    grid-template-columns: 1.5fr 0.75fr;
  }

  .section-appointment_layout-copy {
    -ms-grid-columns: auto minmax(200px, 3.25fr);
    grid-template-columns: auto minmax(200px, 3.25fr);
  }

  .section-appointment-details-copy-copy {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 11px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1.5fr 1.5fr;
    grid-template-columns: 1.5fr 1.5fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .section-appointment-details-copyy {
    grid-column-gap: 6px;
    -ms-grid-columns: 1.5fr 0.75fr;
    grid-template-columns: 1.5fr 0.75fr;
  }

  .icon-size-back {
    height: 2.4rem;
    min-width: 20px;
  }

  .item-image-copy {
    height: 120px;
    border-style: solid;
  }

  .div-block-8 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

#w-node-_99e5448b-ff66-3164-d2bd-0dc06990e622-f511f45a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b28849d9-8513-6333-dfcd-7af34ea091ba-f511f45a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f49a85b1-ab2a-5c07-b8b4-d2f2656122ed-f511f45a {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b611-ad32b5ad {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b61a-ad32b5ad {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b685-ad32b5ad {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b686-ad32b5ad {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b687-ad32b5ad {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b698-ad32b5ad {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b6b2-ad32b5ad {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b6b3-ad32b5ad {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b6b4-ad32b5ad {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b6c5-ad32b5ad {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b6dd-ad32b5ad {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b6de-ad32b5ad {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b6df-ad32b5ad {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_141333b0-f15e-7789-05d3-cfe050eedbc0-50eedbbf {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b9921240-64396e5d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b9921241-64396e5d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b9921242-64396e5d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b9921253-64396e5d {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b992127e-64396e5d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b992127f-64396e5d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b9921280-64396e5d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b9921291-64396e5d {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b99212a9-64396e5d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b99212aa-64396e5d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b99212ab-64396e5d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b99212be-64396e5d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b99212bf-64396e5d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b99212c0-64396e5d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b99212d1-64396e5d {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b99212e9-64396e5d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b99212ea-64396e5d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-ae0640ab-3a13-afe6-06fc-9856b99212eb-64396e5d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c7662283e-79396e5e {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c76622851-79396e5e {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_842598f2-bfa6-a9b7-7c8b-d4a318fc50f0-79396e5e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_13ae5f6c-2eb3-df04-e6c9-a8e08746dc3e-79396e5e {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_7b341f6b-632d-b774-8751-d4cdf1c784f8-79396e5e {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c76622882-79396e5e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c76622883-79396e5e {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c76622884-79396e5e {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c76622895-79396e5e {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c766228c0-79396e5e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c766228c1-79396e5e {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c766228c2-79396e5e {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c766228d3-79396e5e {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c766228eb-79396e5e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c766228ec-79396e5e {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c766228ed-79396e5e {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c76622900-79396e5e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c76622901-79396e5e {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c76622902-79396e5e {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c76622913-79396e5e {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c7662292b-79396e5e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c7662292c-79396e5e {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_92ec8033-ab5c-d260-6e9b-d45c7662292d-79396e5e {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e4680-a7396e61 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_9c78b3af-aa07-1138-ebf8-cfa06d5817dc-a7396e61 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e4681-a7396e61 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e4682-a7396e61 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e4697-a7396e61 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e4698-a7396e61 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e46a4-a7396e61 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e46a5-a7396e61 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_620fb22b-65a7-9252-98a0-bcd189bcf394-a7396e61 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_620fb22b-65a7-9252-98a0-bcd189bcf397-a7396e61 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f7c8adcc-f0cb-4026-dce7-582d7afd050c-a7396e61 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7cb2049-c73c-159e-190b-9bfe989cc15d-a7396e61 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-fde26636-91dd-8a77-ced9-ae7d425690f2-a7396e61 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d07a3d17-564a-7816-b830-acac34fa4ee8-a7396e61 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_1175d3ba-3175-b4d5-68ed-81edc7b80916-a7396e61 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_48a07ef4-9898-0f17-df6b-f65f37e5561c-a7396e61 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-a9ab2161-703e-1d84-e96a-da4f84dbd21f-3b396e62 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-bc63729c-b6ac-124a-3dcc-58bf824613b2-3b396e62 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f0ff40f1-fef3-99ee-56d1-d8c72b61639e-3b396e62 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_9311d674-7177-b551-898d-a4b8029d86b8-3b396e62 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d19b657f-e6c2-fef2-3ea9-7ba188962848-3b396e62 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_1236d67d-4d1b-2a0a-2b98-eff96418d65f-3b396e62 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-fa4909b2-a9be-72a6-5bcc-f0e7bd3026b1-59396e63 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_8a5f6128-40eb-636e-d09f-e904ec6a69c9-59396e63 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-fa4909b2-a9be-72a6-5bcc-f0e7bd3026b2-59396e63 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fa4909b2-a9be-72a6-5bcc-f0e7bd3026b3-59396e63 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_8cd68c42-e279-9684-0f35-575fbe5a0a29-59396e63 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_8cd68c42-e279-9684-0f35-575fbe5a0a2a-59396e63 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_8cd68c42-e279-9684-0f35-575fbe5a0a33-59396e63 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_8cd68c42-e279-9684-0f35-575fbe5a0a34-59396e63 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5387b8d4-dd05-66d2-7630-c2c4b0eb712e-59396e63 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_5387b8d4-dd05-66d2-7630-c2c4b0eb712f-59396e63 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_5387b8d4-dd05-66d2-7630-c2c4b0eb7130-59396e63 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-fa4909b2-a9be-72a6-5bcc-f0e7bd3026c2-59396e63 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_1db3cce4-b6aa-0d3e-3fc0-6a81c44e0bfd-59396e63 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_86605f05-492c-9587-ec94-b2b585750fea-5e396e64 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-c76397a0-d167-1c31-60df-276f0a628819-5e396e64 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_86605f05-492c-9587-ec94-b2b585750feb-5e396e64 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_86605f05-492c-9587-ec94-b2b585750fec-5e396e64 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_86605f05-492c-9587-ec94-b2b585750ff4-5e396e64 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_86605f05-492c-9587-ec94-b2b585750ff5-5e396e64 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_86605f05-492c-9587-ec94-b2b585750ffe-5e396e64 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_86605f05-492c-9587-ec94-b2b585750fff-5e396e64 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_86605f05-492c-9587-ec94-b2b585751004-5e396e64 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_86605f05-492c-9587-ec94-b2b585751005-5e396e64 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_86605f05-492c-9587-ec94-b2b585751006-5e396e64 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-ba1beb20-2341-1625-e3d5-79d6bf624187-5e396e64 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-ba1beb20-2341-1625-e3d5-79d6bf62419b-5e396e64 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ba1beb20-2341-1625-e3d5-79d6bf62419c-5e396e64 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-ba1beb20-2341-1625-e3d5-79d6bf62419d-5e396e64 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-c8464c7d-428a-5229-9887-6e63673da370-3f396e65 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_9258b101-df57-740d-8897-ceaa6969ce6a-3f396e65 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-c8464c7d-428a-5229-9887-6e63673da371-3f396e65 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c8464c7d-428a-5229-9887-6e63673da372-3f396e65 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c8464c7d-428a-5229-9887-6e63673da37a-3f396e65 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c8464c7d-428a-5229-9887-6e63673da37b-3f396e65 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-c8464c7d-428a-5229-9887-6e63673da384-3f396e65 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c8464c7d-428a-5229-9887-6e63673da385-3f396e65 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c8464c7d-428a-5229-9887-6e63673da38a-3f396e65 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-c8464c7d-428a-5229-9887-6e63673da38b-3f396e65 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-c8464c7d-428a-5229-9887-6e63673da38c-3f396e65 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-b1eb0d91-fdb8-6523-001b-8ee28c073994-3f396e65 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-b1eb0d91-fdb8-6523-001b-8ee28c073995-3f396e65 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_3fa3d134-f9f6-9b23-2b6f-5910a64417bf-c8396e66 {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_70649d4b-8e7b-cd7f-55d1-d4f2059ebe72-c8396e66 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_3fa3d134-f9f6-9b23-2b6f-5910a64417c0-c8396e66 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_3fa3d134-f9f6-9b23-2b6f-5910a64417c1-c8396e66 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_791d0a9a-e4b4-ea3d-5980-4c788911e476-c8396e66 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_9c98bc1b-5afe-7c0a-ef67-1c1b5db04d42-c8396e66 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_8bb67a82-2a61-ef4f-3026-4ed40374996a-c8396e66 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_5f12b85f-dd06-c0d7-b89c-899cfe80aeb5-c8396e66 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_607148af-a3da-0caa-f2c3-e5ff357de793-c8396e66 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_607148af-a3da-0caa-f2c3-e5ff357de794-c8396e66 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_682a36de-6f92-cfb9-ee52-1aeeb95778c6-c8396e66 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_7fcdd204-64d3-4fb7-9803-f1b40cd4a42c-c8396e66 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_7fcdd204-64d3-4fb7-9803-f1b40cd4a42d-c8396e66 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-d8fc041e-5196-adc4-5890-7f5940b87673-c8396e66 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-f74d0568-ddc7-4df1-9eb2-6d8cf08a9cd0-45396e68 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_67537186-6b88-7cde-1cda-06daae5a7738-45396e68 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_67537186-6b88-7cde-1cda-06daae5a774b-45396e68 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_67537186-6b88-7cde-1cda-06daae5a7762-45396e68 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_67537186-6b88-7cde-1cda-06daae5a7771-45396e68 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_67537186-6b88-7cde-1cda-06daae5a7784-45396e68 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_8852ac24-3fd6-6794-2eb3-74c9e98924c2-45396e68 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_8e3775e5-fe28-4b9a-a1a9-09faeb4ee172-45396e68 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_94caf3dd-38cb-9fb6-8927-8d10ff4b7faa-45396e68 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_8920af60-c3b8-2444-bbc2-30a0d56fbde1-45396e68 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_8158c332-59f3-35c9-8b1f-cc0262b6987f-45396e68 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_5aae6a6c-56c8-086b-f01c-0c31eb3a399a-60396e69 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_8be58e1a-9b3b-4412-5bbd-3f7ad76aa3a8-60396e69 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_5aae6a6c-56c8-086b-f01c-0c31eb3a399b-60396e69 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5aae6a6c-56c8-086b-f01c-0c31eb3a399c-60396e69 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-a14c1fd7-32fb-e7c4-6b1a-53edf0ae8af4-dd396e6a {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-b4685688-dda1-4943-2ba0-f7443a8dd449-dd396e6a {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-a14c1fd7-32fb-e7c4-6b1a-53edf0ae8af5-dd396e6a {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-a14c1fd7-32fb-e7c4-6b1a-53edf0ae8af6-dd396e6a {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-a14c1fd7-32fb-e7c4-6b1a-53edf0ae8b07-dd396e6a {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

@media screen and (max-width: 479px) {
  #w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b62a-ad32b5ad {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b630-ad32b5ad {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b636-ad32b5ad {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b63c-ad32b5ad {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_3d34eca0-a143-79b2-e1d3-f517ad32b685-ad32b5ad {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-a8ef372e-cdd2-44ac-353c-b3724053a695-50eedbbf {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }

  #w-node-_70de13f0-3af9-bcc2-e80b-6fe5c64e4680-a7396e61 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_3fa3d134-f9f6-9b23-2b6f-5910a64417bf-c8396e66 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_3fa3d134-f9f6-9b23-2b6f-5910a64417c1-c8396e66 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_791d0a9a-e4b4-ea3d-5980-4c788911e476-c8396e66 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_8bb67a82-2a61-ef4f-3026-4ed40374996a-c8396e66 {
    -ms-grid-column-align: start;
    justify-self: start;
  }
}

