
.body {
    font-size: 1rem;
    background: rgba(248, 248, 248, .5);
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 0px;
}

.mg-at{
    max-width:1000px;
    margin:auto
}

.header {
    background-color:white;
    display:flex;
    height:92px;
    font-size:1.5rem;
    align-items:center;
    font-weight:bold;
    padding-left:1rem
}

.container {
    /* height:calc(100vh - 92px); */
    display:flex;
    flex-direction: row;
    border-top: 1px solid #E5E5E5;
}

.slider {
    width: 266px;
    background-color:white;
    font-weight: 600;
    color:#A4A6B3;
}

.content {
    width:calc(100% - 266px);
    padding:2rem 6rem 0 6rem;
}

.slider > a {
    color:#A4A6B3;
    text-decoration: none;
}

.slider > a > div {
    padding:1.5rem;
}

.slider > a > div > span {
    padding-right: .5rem;
}

.slider > a:hover {
    color: black !important;
}
/* -------------------------- */

/* elements */

.toggle {
    display: flex;
    background-color:rgba(237, 237, 237, 0.6);
    width: 324px;
    border-radius: 8px;
    padding: .5rem;
    color: #6F7588;
    font-weight: 600;
}

.toggle > div {
    width: 158px;
    text-align: center;
    padding: 8px 20px;
    border-radius: 8px;
}

.select {
    background-color:white !important;
    color:#F8633B !important;
}

.df-fdc{
    display:flex;
    flex-direction:column;
}

.c-gry{
    color:#8A9198
}

.c-white{
    color:white
}

.j-csb{
    justify-content:space-between
}

.a-sc{
    align-self: center;
}

.f-w400{
    font-weight:400
}

.f-w600{
    font-weight:600
}

.f-w700{
    font-weight:700
}

.f-s18{
    font-size: 18px;
}

.f-s16{
    font-size: 16px;
}

.f-s14{
    font-size: 14px;
}

.f-s24{
    font-size: 24px;
}

.f-s20{
    font-size: 20px;
}

.c-blk{
    color:black
}

.c-org{
    color:#F8633B;
}

.test{
    border:1px solid black;
}

.tmp-img{
    width:98px;
    height:98px;
    background-color:#8A9198;
    border-radius:.5rem;
    margin:1rem
}

.df-fdr{
    display:flex;
    flex-direction:row;
}

.mg-1rem{
    margin:1rem;
}

.pd-1rem{
    padding:1rem;
}

.pd-5rem{
    padding:.5rem;
}

.anf_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.apt_notfound{
    padding: 1.876rem;
    background-color: #E5E5E5;
    max-width: fit-content;
    border-radius: 12px;
}

.at-bt-cv {
    display:flex;
    padding:1rem;
    padding-top:0
}

.p-btn {
    width:152px;
    padding:.876rem .5rem;
    margin-right: 5px;
    border:1px solid #E5E5E5;
    border-radius:28px;
    text-align:center;
    font-weight:700;
    color:#F8633B
}

.p-btn:hover {
    background-color:#F8633B;
    color:white;
}

.appoint-container{
    width:436px;
    background-color:white;
    margin-top:1rem;
    border-radius:8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.appoint-container:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.col {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.input{
    padding: 0.5rem 0.876rem;
    width: 98%;
    border-radius: 4px;
    border: 1px solid #E6E6E8;
    font-size: 20px;
}

.fg_fb{
    flex-grow: 1;
    flex-basis: 0;
}

.box_shadow{
    box-shadow: 0px 6.017093658447266px 12.034187316894531px 0px #00000014;
    max-width: 12.313rem;
    min-height: 13.25rem;
    border-radius: 6px;
}

.box_shadow:hover{
    box-shadow: 10px 9.017093658447266px 15.034187316894531px 0px #00000014;
}

.go_back{
    background-color:#F8E7DEE5;
    padding: 0.5rem 0.6rem;
    border-radius: 50%;
    margin-right: 1rem;
}